@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Nunito;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(204, 201, 250);
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(142,133,231);
    border-radius: 6px;
    border: 2px solid rgb(157, 148, 243);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(118, 109, 250);
}